<template>
  <div class="page-container">
    <div class="page-route-select">
      <router-link to="/cliente/monitores/cotacoes" class="page-route-select-text">
        Home
      </router-link>
      <span class="material-icons page-route-select-icon">play_arrow</span>
      <div class="page-route-select-text">
        Configurações da Conta
      </div>
    </div>
    <div class="page-title">
      Configurações da Conta
    </div>
    <div class="resale-settings__actions-container">
      <Button class="action-buttons" :iconName="'add_circle'" title="Meus Usuários" :text="'Meus Usuários'" :action="() => { show_user_management_modal = true }" />
    </div>
    <cc-loader v-show="loading" />
    <div class="main-form__container">
      <ValidationObserver v-slot="{ handleSubmit }">
        <form action="" v-if=" !loading" @submit.prevent="handleSubmit(save)">
          <div class="main-form__inputs-wrapper">
            <ValidationProvider class="requests-tab__product-input-container" rules="required" v-slot="v">
              <div class="requests-tab__input-title-wrapper">
                <span class="requests-tab__input-title">Nome</span>
                <span class="material-icons info-icon" title="Campo obrigatório">
                  info
                </span>
              </div>
              <div style="display: flex; align-items: center;">
                <input class="requests-tab__user-info-input" id="resale-name" v-model="resale.name" placeholder="Digite o nome" type="text">
                <span class="material-icons-outlined edit-icon">
                  edit
                </span>
              </div>
              <span class="error">{{ v.errors[0] }}</span>
            </ValidationProvider>
            <ValidationProvider class="requests-tab__product-input-container" rules="required|cnpj" v-slot="v">
              <div class="requests-tab__input-title-wrapper">
                <span class="requests-tab__input-title">CNPJ</span>
                <span class="material-icons info-icon" title="Campo obrigatório">
                  info
                </span>
              </div>
              <div style="display: flex; align-items: center;">
                <input class="requests-tab__user-info-input" id="resale-cnpj" v-mask="'## ### ###/####-##'" v-model="resale.cnpj" placeholder="Digite o CNPJ" type="text">
                <span class="material-icons-outlined edit-icon">
                  edit
                </span>
              </div>
              <span class="error">{{ v.errors[0] }}</span>
            </ValidationProvider>
          </div>
          <div class="main-form__inputs-wrapper">
            <ValidationProvider class="requests-tab__product-input-container" rules="required" v-slot="v">
              <div class="requests-tab__input-title-wrapper">
                <span class="requests-tab__input-title">Telefone</span>
                <span class="material-icons info-icon" title="Campo obrigatório">
                  info
                </span>
              </div>
              <div style="display: flex; align-items: center;">
                <input class="requests-tab__user-info-input" id="resale-phone" v-mask="'(##) #.####-####'" v-model="resale.phone" placeholder="Digite o telefone">
                <span class="material-icons-outlined edit-icon">
                  edit
                </span>
              </div>
              <span class="error">{{ v.errors[0] }}</span>
            </ValidationProvider>
            <ValidationProvider class="requests-tab__product-input-container" v-slot="v">
              <div class="requests-tab__input-title-wrapper">
                <span class="requests-tab__input-title">Email</span>
                <span class="material-icons info-icon" title="Campo obrigatório">
                  info
                </span>
              </div>
              <div style="display: flex; align-items: center;">
                <input class="requests-tab__user-info-input" id="resale-email" v-model="resale.mail" placeholder="Digite o Email" type="text">
                <span class="material-icons-outlined edit-icon">
                  edit
                </span>
              </div>
              <span class="error">{{ v.errors[0] }}</span>
            </ValidationProvider>
          </div>
          <div class="main-form__inputs-wrapper">
            <ValidationProvider class="requests-tab__product-input-container" rules="required" v-slot="v">
              <div class="requests-tab__input-title-wrapper">
                <span class="requests-tab__input-title">Contato</span>
                <span class="material-icons info-icon" title="Campo obrigatório">
                  info
                </span>
              </div>
              <div style="display: flex; align-items: center;">
                <input class="requests-tab__user-info-input" id="resale-contact" v-model="resale.contact" placeholder="Digite o contato">
                <span class="material-icons-outlined edit-icon">
                  edit
                </span>
              </div>
              <span class="error">{{ v.errors[0] }}</span>
            </ValidationProvider>
            <ValidationProvider class="requests-tab__product-input-container" v-slot="v">
              <div class="requests-tab__input-title-wrapper">
                <span class="requests-tab__input-title">Site</span>
                <span class="material-icons info-icon" title="Campo obrigatório">
                  info
                </span>
              </div>
              <div style="display: flex; align-items: center;">
                <input class="requests-tab__user-info-input" id="resale-website" v-model="resale.site" placeholder="Digite o site" type="text">
                <span class="material-icons-outlined edit-icon">
                  edit
                </span>
              </div>
              <span class="error">{{ v.errors[0] }}</span>
            </ValidationProvider>
          </div>
          <div v-if="message" class="col-md-12 col-lg-12">
            <h4 class="py-4 text-center text-danger">{{ message }}</h4>
          </div>
          <div class="col d-flex justify-content-center">
            <button class="btnFeedbackModal" type="submit">Salvar</button>
          </div>
        </form>
      </ValidationObserver>
    </div>
    <user-management-modal :resale="resale"
                           v-if="show_user_management_modal"
                           @close="show_user_management_modal=false" />
  </div>
    <!-- <div class="monitors">
      <div class="body">
            <div class="container-fluid">
                <div class="row">
                    <div class="col modal-header d-block">
                        <h5>
                            <i class="header-icon" :class="'fas fa-user-cog'"></i>
                            Minha Conta Club
                        </h5>
                        <p class="subtitle">Gerencie suas informações</p>
                    </div>
                    <div class="col-10">
                        <div class="row py-3 d-flex justify-content-end">
                            <div class="col col-md-2 col-lg-2 filter-controls">
                                <cc-button :classes="'fixed secondary'" :icon="'fas fa-users'" :content="'Meus Usuários'" @click="show_user_management_modal=true" />
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-12 p-4 shadow rounded">
                        <cc-loader v-show="loading" />
                        <ValidationObserver v-slot="{ handleSubmit }">
                            <form action="" v-if=" !loading" @submit.prevent="handleSubmit(save)">

                            <div class="row">
                                <div class="col col-md-4 col-lg-5">
                                    <label>Nome:<span style="color:red !important">*</span></label>
                                    <ValidationProvider rules="required" v-slot="v">
                                        <cc-input type="text" id="resale-name" v-model="resale.name" />
                                        <span class="error">{{ v.errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                                <div class="col col-md-3 col-lg-4">
                                    <label>CNPJ:<span style="color:red !important">*</span></label>
                                    <ValidationProvider rules="required|cnpj" v-slot="v">
                                        <cc-input type="text" id="resale-cnpj" v-mask="'## ### ###/####-##'" v-model="resale.cnpj"></cc-input>
                                        <span class="error">{{ v.errors[0] }}</span>
                                    </ValidationProvider>
                                </div>

                                <div class="col col-md-2 col-lg-3">
                                    <label>Telefone:<span style="color:red !important">*</span></label>
                                    <ValidationProvider rules="required" v-slot="v">
                                        <cc-input type="text"
                                            id="resale-phone"
                                            v-mask="'(##) #.####-####'"
                                            v-model="resale.phone" />
                                        <span class="error">{{ v.errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col col-md-3 col-lg-4">
                                    <label>Email:<span style="color:red !important">*</span></label>
                                    <ValidationProvider rules="required" v-slot="v">
                                        <cc-input type="text" id="resale-email" v-model="resale.mail" />
                                        <span class="error">{{ v.errors[0] }}</span>
                                    </ValidationProvider>
                                </div>

                                <div class="col col-md-3 col-lg-4">
                                    <label>Contato:<span style="color:red !important">*</span></label>
                                    <ValidationProvider rules="required" v-slot="v">
                                        <cc-input type="text" id="resale-contact" v-model="resale.contact"></cc-input>
                                        <span class="error">{{ v.errors[0] }}</span>
                                    </ValidationProvider>
                                </div>

                                <div class="col col-md-3 col-lg-4">
                                    <label>Site:<span style="color:red !important">*</span></label>
                                    <ValidationProvider rules="required" v-slot="v">
                                        <cc-input type="text" id="resale-website" v-model="resale.site"></cc-input>
                                        <span class="error">{{ v.errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                            </div>

                            <div class="row pt-2">
                                <div v-if="message" class="col-md-12 col-lg-12">
                                    <h4 class="py-4 text-center text-danger">{{message}}</h4>
                                </div>
                                <div class="col d-flex justify-content-center">
                                    <button class="btnFeedbackModal" type="submit">Salvar</button>
                                </div>
                            </div>
                        </form>
                        </ValidationObserver>
                    </div>
                </div>
            </div>
        </div>
        <user-management-modal
            :resale="resale"
            v-if="show_user_management_modal"
            @close="show_user_management_modal=false" />
    </div> -->
</template>

<script>
import AuthService from '@/services/AuthService'
import ResaleServie from "@/services/v3/resales/resale.service";
import ErrorHandlerService from "@/services/ErrorHandlerService";
import UserManagementModal from "@/modals/resale/users/management.modal";
import { loaderMixin } from '@/mixins/sweet-loader.mixin'
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import Button from '@/components/ui/buttons/StandardButton.vue'

export default {
    mixins: [ loaderMixin ],
    components: {
        ValidationObserver,
        ValidationProvider,
        'user-management-modal': UserManagementModal,
        Button,
    },
    data(){
        return{
          image:"/images/search.png",
            loading: false,
            resale: {},
            stores: [],
            profiles: [],
            message: null,
            show_user_management_modal: false,
            service: new AuthService(),
            svc: new ResaleServie(),
            breadcrumbs: [
                {
                    name: "Minhas Configurações",
                    route: 'settings'
                }
            ]
        }
    },
    methods: {
        save() {
            this.message = null
            this.present_loader('Salvando..')
			let callback = data => {
				this.dismiss_loader()
                const notification = {
                    type: 'success',
                    message: `Revenda atualizada com sucesso!`
                }
                this.$store.dispatch('notification/add', notification)
                this.$emit("reload");
			}
			let callback_error = err => {
				this.dismiss_loader()
				if(err.response && err.response.data && err.response.data.errors) {
                    let errors = err.response.data.errors
					this.message = Object.values(errors).flat()[0]
				} else {
					this.message = 'Ops! Houve um erro ao salvar informações, tente novamente mais tarde!'
				}
                ErrorHandlerService.handle(err, this.$store);
			}
			this.svc.update(this.resale).then(callback, callback_error)
		},
    },
    created() {
        this.loading = true
        this.service.get_user().then(response => response.data).then(({ usuario }) => {
            this.svc.show(usuario.usu_id_revenda).then(response => response.data).then(resale => {
                this.resale = resale
                this.loading = false
            })
        }, err => {
            this.loading = false;
            ErrorHandlerService.handle(err, this.$store, this.$store);
        })
        this.$store.dispatch('set_breadcrumbs', this.breadcrumbs);
    }
}
</script>

<style lang="scss" scoped>
    @import '@/sass/commons/_variables';

    .error {
        color: $lower-red;
    }

    .btnFeedbackModal {
      padding: 20px 40px;
      background-color: #FF7110;
      color: #fff;
      border: 1px solid transparent;
      border-radius: 8px;
      font-weight: 600;
      font-size: 16px;
    }
    .btnFeedbackModal:hover {
      background-color: #fff;
      color: #FF7110;
      border: 1px solid #FF7110;
    }

    .modal-header h3,.modal-header h4, .modal-header h5 {
        margin-top: 0;
        font-weight: bolder;
        font-size: 1.15rem;
        color: $secondary-color;
        text-transform: uppercase;
    }

    .modal-header {
        border: none;
        .subtitle {
            box-shadow: inset 7px 0px 0px 0px rgba(66,103,178,1);
            padding-left: 1rem;
            color: #314b5f;
            font-size: 0.9rem;
            font-family: Helvetica,sans-serif !important;
            font-weight: lighter;
        }
    }

.page-container {
  font-size: 1vw;
  margin-right: 4em;
}
.page-route-select {
  display: flex;
  align-items: center;
}
.page-route-select-text {
  font-weight: 400;
  font-size: 1.36em;
  line-height: 17px;
  color: #ABABAB;
  margin-right: 20px;
  cursor: pointer;
}
.page-route-select-icon {
  color: #ff7110;
  margin-right: 20px;
}
.page-title {
  font-weight: 600;
  font-size: 2.72em;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #605F5F;
  margin-top: 45px;
}
.resale-settings__actions-container {
  display: flex;
  flex-direction: row-reverse;
}
.main-form__container {
  margin: 3em 0em;
}
.main-form__inputs-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 3em;
}
.requests-tab__input-title-wrapper {
  padding-bottom: 5px;
}
.requests-tab__input-title {
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #898989;
  font-size: 1.6em;
}
.requests-tab__product-input-container {
}
.requests-tab__user-info-input {
  border: 1px solid #c2c2c3;
  font-size: 17px;
  padding: 11px;
  border-radius: 5px;
  width: 50vw;
  color: #A1A1A1;
}
.requests-tab__user-info-input:focus {
  border: 1px solid #ff7110 !important;
}
.edit-icon:focus {
  position: relative;
  left: -35px;
  color:  #ff7110;
}
.edit-icon {
  position: relative;
  left: -35px;
  color:  #ff7110;
  cursor: pointer;
}
.info-icon {
  color: #CB4243;
  margin-left: 5px;
  font-size: 18px;
  cursor: pointer;
}

@media only screen and (max-width: 1000px) {
  .page-container { font-size: 1.5vw; }
}
@media only screen and (max-width: 700px) {
  .page-container { font-size: 2vw; }
  .main-form__inputs-wrapper{flex-direction: column;}
  .requests-tab__user-info-input{ width: 100%;}
}
@media only screen and (max-width: 500px) {
  .page-container { font-size: 2.5vw; }
}

</style>
